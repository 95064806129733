










































.ui-external-application-icons {
  display: flex;

  &__icon {
    display: block;
    width: 25px;
    margin: 3px;
  }
}
